<template lang='pug'>
.reports-single-date
  form.form.form-horizontal
    .form-row
      fi-form-field.col-lg-3(
        v-if        = 'report.code !== "taxMmr"'
        :label      = '$t("creditIssuer")'
        :label-cols = 4
      )
        fi-select(
          v-model = 'period.creditIssuerId'
          :options = 'optionsFromCreditIssuers'
        )
      fi-form-field.col-lg-3(
        :label      = '$t("date")'
        :label-cols = 4
      )
        fi-datepicker(
          v-model        = 'period.startDate'
          :enabled-dates = 'report.isMMR ? successDatesMMR : successDates'
        )
      .col
        .btn-group(role = 'group')
          button.btn.btn-outline-primary(
            v-if           = 'report.actionJSON'
            :disabled      = '!period.startDate'
            @click.prevent = 'doReport(report.actionJSON)'
          )
            v-loading(loader = 'reports:json:fetch')
              template(#spinner)
                i.fa.fa-spinner.fa-pulse.ml-1
              i.fa.fa-cloud-download
              | &nbsp;
              | {{ $t('apply') }}
          button.btn.btn-outline-primary(
            v-if           = 'report.actionCSV'
            :disabled      = '!period.startDate'
            @click.prevent = 'doReport(report.actionCSV)'
          )
            v-loading(loader = 'reports:csv:fetch')
              template(#spinner)
                i.fa.fa-spinner.fa-pulse.ml-1
              i.fa.fa-cloud-download
              | &nbsp;
              | {{ $t('downloadCSV') }}
          button.btn.btn-outline-primary(
            v-if           = 'report.actionXML'
            :disabled      = '!period.startDate'
            @click.prevent = 'doReport(report.actionXML)'
          )
            v-loading(loader = 'reports:xml:fetch')
              template(#spinner)
                i.fa.fa-spinner.fa-pulse.ml-1
              i.fa.fa-cloud-download
              | &nbsp;
              | {{ $t('downloadXML') }}
  p {{ report.description }}
  fi-chart-bar.reports-chart(
    v-if        = 'dataCollection'
    :chart-data = 'dataCollection'
    :options    = 'options'
  )
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import RangeInputDate from '@/components/RangeInputDate'
import union from 'lodash/union'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import keys from 'lodash/keys'
import FiChartBar from '@/components/FiChartBar'
import { mapActions, mapGetters, mapState } from 'vuex'
import FiSelect from '@/components/FiSelect'

export default {
  name: 'inspection-reports-single-date',

  components: { FiSelect, FiDatepicker, RangeInputDate, FiFormField, FiChartBar },

  props: {
    report: {
      type: Object,
      required: true
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    period: {
      creditIssuerId: '',
      startDate: ''
    }
  }),

  computed: {
    ...mapState('reports', ['successDates', 'reportsData', 'successDatesMMR']),
    ...mapGetters('classifiers', ['optionsFromCreditIssuers']),
    options () {
      return {
        responsive: true,
        maintainAspectRatio: false
      }
    },
    dataCollection () {
      const { [this.report.code]: reportData = [] } = this.reportsData
      if (reportData.length === 0) {
        return null
      }
      const data = reportData.map(({ data }) => data)
      return {
        labels: reportData.map(({ productName }) => productName),
        datasets: union(flatten(map(data, keys))).map(key => ({
          label: this.$t(key),
          data: data.map(data => data[key]),
          backgroundColor: '#' + Math.floor(Math.random() * 16777215).toString(16)
        }))
      }
    }
  },

  mounted () {
    this.loadSuccessDates()
    this.loadSuccessDatesMMR()
  },

  methods: {
    ...mapActions('reports', ['loadSuccessDates', 'loadSuccessDatesMMR']),
    doReport (reportAction) {
      if (this.report.reportName) {
        this.$emit('run-report', {
          reportAction,
          reportName: this.report.reportName,
          data: this.period
        })
      } else {
        this.$emit('run-report', {
          reportAction,
          data: this.period
        })
      }
    }
  }

}
</script>


<i18n>
en:
  accountsReceivableStart:  "Accounts receivable start"
  accountsReceivableEnd:    "Accounts receivable end"
  principalReceivableStart: "Principal receivable end"
  principalReceivableEnd:   "Principal receivable end"
  interestReceivable:       "Interest receivable"
  interestIncome:           "Interest income"
  serviceReceivable:        "Service receivable"
  serviceIncome:            "Service income"
  penaltyReceivable:        "Penalty receivable"
  penaltyIncome:            "Penalty income"
  agreementFeePeriodised:   "Agreement fee periodised"
  provisionedPrincipal:     "Provisioned principal"
  provisionedInterest:      "Provisioned interest"
  provisionedService:       "Provisioned service"
  provisionedPenalty:       "Provisioned penalty"
  loansAmount:              "Loans amount"
  loansRemainingShortTerm:  "Loans remaining short-term"
  loansRemainingLongTerm:   "Loans remaining long-term"
et:
  accountsReceivableStart:  "Accounts receivable start"
  accountsReceivableEnd:    "Accounts receivable end"
  principalReceivableStart: "Principal receivable end"
  principalReceivableEnd:   "Principal receivable end"
  interestReceivable:       "Interest receivable"
  interestIncome:           "Interest income"
  serviceReceivable:        "Service receivable"
  serviceIncome:            "Service income"
  penaltyReceivable:        "Penalty receivable"
  penaltyIncome:            "Penalty income"
  agreementFeePeriodised:   "Agreement fee periodised"
  provisionedPrincipal:     "Provisioned principal"
  provisionedInterest:      "Provisioned interest"
  provisionedService:       "Provisioned service"
  provisionedPenalty:       "Provisioned penalty"
  loansAmount:              "Loans amount"
  loansRemainingShortTerm:  "Loans remaining short-term"
  loansRemainingLongTerm:   "Loans remaining long-term"
ru:
  accountsReceivableStart:  "Accounts receivable start"
  accountsReceivableEnd:    "Accounts receivable end"
  principalReceivableStart: "Principal receivable end"
  principalReceivableEnd:   "Principal receivable end"
  interestReceivable:       "Interest receivable"
  interestIncome:           "Interest income"
  serviceReceivable:        "Service receivable"
  serviceIncome:            "Service income"
  penaltyReceivable:        "Penalty receivable"
  penaltyIncome:            "Penalty income"
  agreementFeePeriodised:   "Agreement fee periodised"
  provisionedPrincipal:     "Provisioned principal"
  provisionedInterest:      "Provisioned interest"
  provisionedService:       "Provisioned service"
  provisionedPenalty:       "Provisioned penalty"
  loansAmount:              "Loans amount"
  loansRemainingShortTerm:  "Loans remaining short-term"
  loansRemainingLongTerm:   "Loans remaining long-term"
</i18n>
